import sparkle from "../assets/sparkle-noloop.gif";
import sparkle_two from "../assets/sparkle2.gif";
import React from "react";

export default class Sparkles extends React.Component {
  handleSparkles() {
    const sparkleElement = document.querySelector(".sparkle");
    const sparkleElementTwo = document.querySelector(".sparkle-two");
    let sparkleLoc = this.randomizeSparkles();
    let sparkleTwoLoc = this.randomizeSparkles();
    this.placeElement(sparkleElement, sparkleLoc);
    this.placeElement(sparkleElementTwo, sparkleTwoLoc);
  }


  placeElement(el, location) {
    el.style.top = `${location.y}px`;
    el.style.right = `${location.x}px`;
  }

  randomizeSparkles() {
    let x = Math.floor(Math.random() * (window.innerWidth - 35));
    let y = Math.floor(Math.random() * (window.innerHeight - 35));
    return { x: x, y: y };
  }
  // componentWillUnmount(){
  //   window.removeEventListener('scroll');
  // }

  componentDidMount() {
    this.handleSparkles();
    
    setInterval(() => {
      let oldSparkle = document.querySelector(".sparkle");
      let oldSparkleTwo = document.querySelector(".sparkle-two");
      if (oldSparkle && oldSparkleTwo) {
        oldSparkle.src = sparkle;
        oldSparkleTwo.src = sparkle_two;
      }
      this.handleSparkles();
    }, this.props.time);
  }

  render() {
    return (
        <div style={{ height: "100%",width:"100%",pointerEvents:"none"
      }}>
        <img
          style={{ position: "absolute" }}
          src={sparkle}
          className="sparkle"
          alt="sparkle1"
        />
        <img
          style={{ position: "absolute" }}
          src={sparkle_two}
          className="sparkle-two"
          alt="sparkle2"
        />
      </div>
    );
  }
}
