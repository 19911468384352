import React, { useEffect, useState } from "react";
import "../styles/Minter.css";
import { checkForMalls } from "../utils/wallet";
import { getNFTMetaData } from "../utils/mint";

export default function NftGallery(props) {
  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    checkForMallsClicked();
  }, []);

  const checkForMallsClicked = async () => {
    const ownerMalls = await checkForMalls();
    if (ownerMalls) {
      await getNFTMetaData(ownerMalls).then((data) => {
        setNfts(data);
      });
    }
  };

  return (
    <div className="mt-3">
      {nfts && nfts.length > 0 ? (
        <div>
          <h2 style={{color: "white", textAlign: "center", fontSize: "28px", textDecoration:"underline" }}>Darth Malls Purchased</h2>
          <ul>
            {nfts.map((nft, index) => (
              <li className="gallery-wrapper" key={index}>
                <div className="col col-6">
                  {" "}
                  <img className="mint-image" src={nft.image} alt={index} />
                </div>
                <div className="col col-6">
                  <p className="malls-title">{nft.name}</p>
                  <p className="malls-description ">{nft.description}</p>
                  <p className="malls-attributes ">ATTRIBUTES</p>
                  <p className="malls-attributes ">
                    {nft.attributes[0].trait_type}
                    {":  "}
                    {nft.attributes[0].value}
                  </p>
                  <p className="malls-attributes ">
                    {nft.attributes[1].trait_type}
                    {":  "}
                    {nft.attributes[1].value}
                  </p>
                  {nft.attributes.length > 2 ? (
                    <p className="malls-attributes ">
                      {nft.attributes[2].trait_type}
                      {":  "}
                      {nft.attributes[2].value}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="check-mints-container">
          <p className="status-text">No Malls detected </p>
          <p className="status-text button" onClick={props.rerender}>
            scan wallet for Malls
          </p>
        </div>
      )}
    </div>
  );
}
