import twitterLogo from '../assets/twitter-logo.svg';
import '../styles/App.css';
import '../styles/utils.css'

export default function Footer() {
  // Constants
  const FMD = "FantomMenaceDAO";
  const TWITTER_LINK_FMD = `https://twitter.com/${FMD}`;
  return (
    <div className="footer-container mt-4">
      <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
      <a
        className="footer-text"
        href={TWITTER_LINK_FMD}
        target="_blank"
        rel="noreferrer"
      >{`built by @${FMD}`}</a>
    </div>
  );
}
