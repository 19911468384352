import Footer from "./components/Footer";
import Header from "./components/Header";
import Minter from "./components/Minter";
import Background from "./components/Background";
import Player from "./components/Player";
import music from "./assets/music.mp3";

// const OPENSEA_LINK = '';
// const TOTAL_MINT_COUNT = 88;

export default function App() {
  
  return (
    <div style={{textAlign:"center",overflow:"hidden" }}>
      <Background>
        <Header />
        <Minter />
        <Player url={music} />
        <Footer />
      </Background>
    </div>
  );
}
