import abi from "../utils/DarthMalls.json";
import { ethers } from "ethers";
import axios from "axios";
import { CONTRACT_ADDRESS, NFT_PRICE } from "./constants";

async function _getContract(isSigner = false, ethereum) {
  // get the signer and contract
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();

  // get chainId
  // const { chainId } = await provider.getNetwork();

  // connnect contract using its address and if it is a provider or signer
  const connectedContract = new ethers.Contract(
    CONTRACT_ADDRESS, // from constants
    abi.abi,
    isSigner ? signer : provider
  );

  return connectedContract;
}

export const getNFTCount = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const connectedContract = await _getContract(false, ethereum);
      const mintedAmount = await connectedContract.getTotalNFTsMintedSoFar();
      return mintedAmount.toString();
    }
  } catch (error) {
    console.log(error);
  }
};

export const askContractToMintNft = async () => {
  try {
    const { ethereum } = window;
    if (ethereum) {
      const connectedContract = await _getContract(true, ethereum);

      // connectedContract.on("MintEvent", async (from, tokenId) =>{
      //   console.log(from, tokenId.toNumber());
      //   const tokenURI = await connectedContract.tokenURI(tokenId);

      //   const meta = await axios.get(
      //     `https://gateway.ipfs.io/ipfs/${tokenURI}/${tokenId}.json`
      //   );

      //   let item = {
      //     tokenID: tokenId,
      //     name: meta.data.name,
      //     image: meta.data.image,
      //     attributes: meta.data.attributes,
      //   };
 
      //   return item;
      // });

      //Get price
      let price = await connectedContract.cost();
      price = price.toString();

      console.log("Going to pop wallet now to pay gas...");

      const nftTxn = await connectedContract.mint(1, {
        value: ethers.utils.parseEther(`${NFT_PRICE}`),
      });

      //Await minting process
      console.log("Minting...please wait.");
      await nftTxn.wait();
      return nftTxn;
      console.log(
        `Minted, see transaction: https://ftmscan.com/tx/${nftTxn.hash}`
      );

    } else {
      console.log("ftm object object doesn't exist!");
    }
  } catch (e) {
    console.log(e);
  }
};

export const getNFTMetaData = async (tokenIds) => {
  if (tokenIds.length === 0) {
    console.log("no token ids passed");
    return;
  }

  try {
    const { ethereum } = window;

    if (ethereum) {
      //Get contract
      const connectedContract = await _getContract(false, ethereum);
      const items = [];
      tokenIds.forEach(async (tokenId) => {
        //Get token address
        const tokenURI = await connectedContract.tokenURI(tokenId);
        //Get NFT metadata by tokenURI
        const meta = await axios.get(
          `https://gateway.ipfs.io/ipfs/${tokenURI}/${tokenId}.json`
        );

        let item = {
          tokenID: tokenId,
          name: meta.data.name,
          image: meta.data.image,
          description: meta.data.description,
          attributes: meta.data.attributes,
        };
        //Add item to total items
        items.push(item);
      });
      return items;
    } else {
      console.log("No such NFT exists!");
    }
  } catch (error) {
    console.log(error);
  }
};

// async function getIPFSBase64(cidOrURI) {
//     const bytes = await this.getIPFS(cidOrURI)
//     return uint8ArrayToString(bytes, 'base64')
// }

// async function getIPFS(cidOrURI) {
//     const cid = stripIpfsUriPrefix(cidOrURI)
//     return uint8ArrayConcat(await all(this.ipfs.cat(cid)))
// }
