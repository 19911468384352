import abi from "../utils/DarthMalls.json";
import { ethers } from "ethers";
import { CONTRACT_ADDRESS } from "./constants";

export const connectWallet = async () => {
  const { ethereum } = window;

  if (!ethereum) {
    alert("Get MetaMask!");
    return {
      address: "",
      status: "Metamask not connected, please connect metamask",
    };
  } else {
    try {
      const accounts = ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("Connected", accounts[0]);
      return { address: accounts[0], status: "Wallet has been connected" };
    } catch (error) {
      console.log(error);
      return {
        address: "",
        status: `Error: ${error.message}`,
      };
    }
  }
};

export const checkForMalls = async () => {
  const { ethereum } = window;
  if (ethereum) {
    try {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const accounts = await ethereum.request({
        method: "eth_accounts",
      });
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS, // from constants
        abi.abi,
        provider
      );
      const balance = await connectedContract.walletOfOwner(accounts[0]);
      console.log(balance.toString());
      return balance;
    } catch (error) {
      console.log(error);
      return {
        address: "",
        status: `Error: ${error.message}`,
      };
    }
  }
};

export const getCurrentWallet = async () => {
  const { ethereum } = window;

  if (!ethereum) {
    alert("Get MetaMask!");
    return {
      address: "",
      status: "Metamask not connected, please connect metamask",
    };
  } else {
    try {
      const accounts = await ethereum.request({
        method: "eth_accounts",
      });
      if (accounts.length > 0) {
        console.log("Connected", accounts[0]);
        return {
          address: accounts[0],
          status: `${accounts[0]} connected`,
        };
      } else {
        return {
          address: "",
          status: "Metamask not connected, please connect metamask",
        };
      }
    } catch (error) {
      console.log(error);
      return {
        address: "",
        status: `Error: ${error.message}`,
      };
    }
  }
};
