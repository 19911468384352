import darthLogo from "../assets/darth-malls_logo.svg";
import deathstar from "../assets/deathstar.gif";
import "../styles/utils.css";
import "../styles/Header.css";

export default function Header() {
  return (
    <div className="header">
      <div className="header-container">
        <div className="logo-container">
          <img className="mb-2" width="250" height="250" src={deathstar} alt="deathstar" />
          <img src={darthLogo} alt="logo" />
        </div>
        <p className="description">
        Turmoil has engulfed the Galactic Republic Malls. The supply-chain disruptions across the galaxy are driving up prices and leading to a growing shortage of goods. The time has come to unite as an NFT DAO to fight back.
        </p>
      </div>
    </div>
  );
}
