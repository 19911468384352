import React from "react";
import "../styles/Background.css";
import spaceimage from "../assets/space-background.png";
import mallimage from "../assets/mall-bg.png";
import Sparkles from "./Sparkles";

export default function Background({ children }) {

  return (
    <div
      className="background-container"
      style={{
        backgroundImage: `url(${spaceimage})`,
        backgroundRepeat: "repeat",
      }}
    >
      <Sparkles time="5000" />
      <div className="content-container">{children}</div>
      <div style={{ position: "relative", height: "15vh" }}>
        <div
          className="footer-background"
          style={{
            backgroundImage: `url(${mallimage})`,
            backgroundRepeat: "repeat",
          }}
        ></div>
      </div>
    </div>
  );
}
