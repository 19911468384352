import React, { useEffect, useState } from "react";
import NftGallery from "./NftGallery";
import "../styles/Minter.css";
import {
  connectWallet,
  getCurrentWallet,
} from "../utils/wallet";
import {
  askContractToMintNft,
  getNFTCount,
} from "../utils/mint";

export default function Minter() {
  const [currentAccount, setCurrentAccount] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [currentMintedNumber, setCurrentMintedNumber] = useState("");
  const [currentNftStatus, setCurrentNftStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // declare for this scope
    async function getCurrentWalletConnected() {
      const result = await getCurrentWallet();
      return result;
    }

    async function getCurrentMintedAmount() {
      const result = await getNFTCount();
      return result;
    }

    //function addNftHandler(newNft)
    //get connected wallet and listen for any wallet changes
    getCurrentWalletConnected().then(function (account) {
      setCurrentAccount(account.address);
      setCurrentStatus(account.status);
    });

    getCurrentMintedAmount().then(function (amount) {
      setCurrentMintedNumber(amount);
    });
    addWalletListener();
  }, []);

  const connectWalletClicked = async () => {
    const walletResult = await connectWallet();
    // const mintNumber = await getNFTCount();
    // const ownerMalls = await checkForMalls();
    // setCurrentMintedNumber(mintNumber);
    setCurrentAccount(walletResult.address);
    setCurrentStatus(walletResult.status);
  };

  const setLoadingCallback = () => {
    setLoading(!loading);
  };

  const askToMint = async ()=>{
    try {
      const newMintedNftTxn = await askContractToMintNft();
      setCurrentNftStatus(`Newly minted: ${newMintedNftTxn.hash}`);
    } catch(err) {
      console.log(`Error: ${err}`);
    }
  }

  const addWalletListener = () => {
    const { ethereum } = window;
    if (ethereum) {
      ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
          setCurrentAccount("");
          setCurrentStatus("Connect to Metamask");
        } else if (accounts[0] !== currentAccount) {
          setCurrentAccount(accounts[0]);
          setCurrentStatus("Wallet has been connected");
        }
      });
    } else {
      setCurrentStatus(
        "You must install the Metamask extension in your browser"
      );
    }
  };

  return (
    <div className="mint-container">
      <p className="status-text">{currentStatus}</p>
      <p className="status-text small">{currentNftStatus}</p>
      {currentMintedNumber === undefined ? (
        <p className="status-text">
          Connect wallet to see number of available Darth Malls
        </p>
      ) : (
        <p className="status-text big">
          {currentMintedNumber}/1604 Malls Minted (12.95 FTM)
        </p>
      )}
     
      {/* Show NFT Image and Attributes */}
      {currentAccount === "" ? (
        <p className="status-text">No Wallet Detected</p>
      ) : (
        <NftGallery rerender={setLoadingCallback} />
      )}

      {/* Get user wallet */}
      {currentAccount === "" ? (
        <button onClick={connectWalletClicked} className="wallet-button">
          Connect Wallet
        </button>
      ) : (
        <button onClick={askToMint} className="wallet-button">
          Mint Malls
        </button>
      )}
    </div>
  );
}
